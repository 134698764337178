import Container from "react-bootstrap/Container";
import React from "react";
class About extends React.Component {
	render() {
		return (
				<Container className="section" id="About" style={{marginTop: 50}}>
					<h1>About</h1>
				</Container>
		);
	}
}
export default About;