import "./css/App.min.css";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Team from "./Pages/Team";
import About from "./Pages/About";

export default function App() {
		return (
				<div>
					<Home/>
					<About/>
					<Team/>
					<Contact/>
				</div>
		);
}