import './css/navbar.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './img/logo.webp';
import {List} from 'react-bootstrap-icons';

export default function NavBar() {
	return (
			<Navbar color="dark" bg="dark" expand="lg" style={{position: "sticky", top: 0}}>
				<Container className="navbar">
					<Nav.Link href="#home"><img src={logo} width={50} className="m-2" alt=""/></Nav.Link>
					<Navbar.Brand href="#home"><h3 className="nav-link">PETCS</h3></Navbar.Brand>
					<Navbar.Toggle aria-controls="navbar-nav" className="toggler p-2">
						<List color="white" size={25}></List>
					</Navbar.Toggle>
					<Navbar.Collapse id="navbar-nav" style={{justifyContent: "right"}}>
						<Nav className="ml-auto">
							<Nav.Link href="#Home"><h5 className="nav-link">Home</h5></Nav.Link>
							<Nav.Link href="#About"><h5 className="nav-link">About Us</h5></Nav.Link>
							<Nav.Link href="#Team"><h5 className="nav-link">Our Team</h5></Nav.Link>
							<Nav.Link href="#Contact"><h5 className="nav-link">Contact Us</h5></Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
	);
}