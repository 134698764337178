import Container from "react-bootstrap/Container";
import React from "react";

class Home extends React.Component {
	render() {
		return (
				<Container className="section" id="Home" style={{marginTop: 50}}>
					<h1>Home</h1>
				</Container>
		);
	}
}
export default Home;