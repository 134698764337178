import Container from "react-bootstrap/Container";
import React from "react";

class Contact extends React.Component {
	render() {
		return (
				<Container className="section" id="Contact" style={{marginTop: 50}}>
					<h1>Contact</h1>
				</Container>
		);
	}
}
export default Contact;